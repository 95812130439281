import React from "react";
import styled from "styled-components";
import ProductimgR from "../images/RH136_3D-right.png";

const Wrapper = styled.div`
.bg-dark {
  background-color: #12110C!important;
}
.product-container {
  padding: 50px 0;
  height: auto;
  position: relative;
}
.content-right {
  display: flex;
  justify-content: flex-end;
}
.product-container .col-lg-7,
.product-container .col-lg-5 {
  float: none;
  margin: auto 0;
}
.product-container .container,
.product-container .row {
  height: 100%;
}
h2 {
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.product-container .text2{
  color: #FFFFFF;
  font-size: 22px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 24px;

}
.product-container img {
  width: 507px;
  max-width: 100%;
  position: relative;
  object-fit: contain;
}
.product-container .text1 {
  color: #ffdd00;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .product-container .text2{
    font-size:20px;
    line-height: 30px;
  }
  .product-container .row{
    padding: 50px auto;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
  }
  .product-container {
    height: auto;
    padding: 40px 0;
  }

  .product-container .col-lg-5 {
    display: flex;
    justify-content: center;
  }

  .push-behind {
    padding-top: 0;
  }

  .push-behind .col-lg-7 {
    padding-top: 40px;
  }
}
/*smaller sm*/
@media (max-width: 768px) {
  .product-container img{
    position: absolute;
    height: 250px;
    bottom: 6px;
    left:12px;
    width: auto;
  }
  .product-container .text1{
    font-size:14px;
    line-height: 18px;
    font-weight:700px;
    z-index : 10;
    position: relative;
  }
  .product-container .text2{
    font-size:16px;
    font-weight: 400px;
    line-height: 24px;
    z-index : 10;
    position: relative;
  }
  h2 {
    font-size: 26px;
    line-height: 30px;
    z-index : 10;
    position: relative;
  }
  .product-container .container {
    padding: 8px;
  }
#right{
  right:12px;
  bottom: 6px;
  left: unset;
}
 }
}
`;

const ProductIntroducing = ({ directus }) => (
  <Wrapper>
    <div id="introducing">
      <div className="product-container bg-dark push-forward">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 col-sm-4">
              <img src={ProductimgR} alt="" />
            </div>
            <div className="col-12 col-lg-7 col-sm-8">
              <p className="text1">
                {directus.translations[0].product_introducing_subline}
              </p>

              <h2>
                {directus.translations[0].product_introducing_headline
                  .split("\n")
                  .map((item) => {
                    return (
                      <>
                        {item} <br />
                      </>
                    );
                  })}
              </h2>

              <p className="text2">
                {directus.translations[0].product_introducing_text
                  .split("\n")
                  .map((item) => {
                    return (
                      <>
                        {item} <br />
                      </>
                    );
                  })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
);

export default ProductIntroducing;
