import React from "react";
import styled from "styled-components";

import { Link } from "gatsby";

import { Dropdown } from "react-bootstrap";

import Logo from "../assets/logo.svg";
import Arrow from "../assets/arrow-down.svg";

const Wrapper = styled.header`
  background-color: rgba(18, 17, 12, 0.6);
  position: fixed;
  z-index: 500;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  svg,
  image,
  img   {
    height: 24px;
    margin: 8px 0 8px 8px;
  }
  ul {
    display: none;
  }
  .dropdown {
    margin-right: 8px;
    .dropdown-toggle {
      background-color: transparent;
      border: none;
      border-radius: 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      border: none;
      box-shadow: none;
      img {
        height: 8px;
        width: 14px;
      }
      &:after {
        border: none;
        padding: 0;
        margin-left: 10px;
        vertical-align: middle;
      }
      &:focus,
      &:active,
      &.show {
        border: none !important;
        box-shadow: none !important;
        background-color: rgba(18, 17, 12, 0.8);
      }
    }
    .dropdown-menu {
      border-radius: 0;
      border: none;
      margin-top: 2px;
      font-size: 14px;
      background-color: rgba(18, 17, 12, 0.8);
      margin-right: -8px;
      .dropdown-item {
        color: white;
        &:hover {
          background-color: rgba(18, 17, 12, 1);
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1699px) {
    height: 64px;
    svg,
    image,
    img {
      height: 32px;
      margin: 16px 0 16px 16px;
    }
    .dropdown {
      font-size: 16px;
      margin-right: 24px;
      .dropdown-toggle {
        img {
          height: 7px;
          width: 14px;
        }
      }
    }
    .dropdown-menu {
      font-size: 16px;
      margin-right: -8px;
    }
  }
  @media only screen and (min-width: 1700px) {
    height: 115px;
    a {
      flex-basis: 320px;
    }
    svg,
    image,
    img {
      height: 48px;
      margin: 32px 0 32px 32px;
    }
    .dropdown {
      flex-basis: 320px;
      display: flex;
      justify-content: end;
      font-size: 16px;
      margin-right: 40px;
      .dropdown-toggle {
        img {
          height: 7px;
          width: 14px;
        }
      }
    }

    .dropdown-menu {
      font-size: 16px;
      margin-right: -8px;
    }
    ul {
      display: block;
      margin: 0 auto;
      padding: 0;
      li {
        display: inline-block;
        flex-direction: row;
        a {
          padding: 44px 24px;
          color: #ffffff;
          text-decoration: none;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          display: table;
        }
      }
    }
  }
`;

const Navigation = ({ pageContext }) => {
  let links = [
    {
      anker: "#introducing",
      label: "Introducing RH 136",
    },
    {
      anker: "#benefits",
      label: "Benefits",
    },
    {
      anker: "#about",
      label: "About RH 136",
    },
    {
      anker: "#facts",
      label: "Facts & Numbers",
    },
    {
      anker: "#recommendedProducts",
      label: "All for tire change",
    },
  ];

  if (pageContext.language === "de-DE") {
    links = [
      {
        anker: "#introducing",
        label: "Einführung RH 136",
      },
      {
        anker: "#benefits",
        label: "Leistungen",
      },
      {
        anker: "#about",
        label: "Über den RH 136",
      },
      {
        anker: "#facts",
        label: "Fakten & Zahlen",
      },
      {
        anker: "#recommendedProducts",
        label: "Alles für den Reifenwechsel",
      },
    ];
  } else {
  }

  return (
    <Wrapper>
      <Link to="/">
        <img src={Logo} alt="Logo" />
      </Link>

      <ul>
        {links.map((link) => (
          <li>
            <a href={link.anker}>{link.label}</a>
          </li>
        ))}
      </ul>

      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          {pageContext.availableLanguages.map((availableLanguage) => {
            if (pageContext.language === availableLanguage.languages_code.code)
              return availableLanguage.languages_code.name;
          })}
          <img src={Arrow} alt="arrow" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {pageContext.availableLanguages.map((availableLanguage) => {
            const to = availableLanguage.default
              ? "/"
              : `/${availableLanguage.languages_code.code.substring(0, 2)}`;

            return (
              <Dropdown.Item
                as={Link}
                to={to}
                key={availableLanguage.languages_code.code}
              >
                {availableLanguage.languages_code.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default Navigation;
