import React from "react";
import { H2, H4 } from "./basis/headlines";

import styled from "styled-components";

import { Container, Row, Col } from "react-bootstrap";

import Benefit from "../components/benefit";

import Background from "../images/parallax-bg.jpg";
import ProductivityIcon from "../images/icons/prod_icon.svg";
import SafetyIcon from "../images/icons/safety-icon.svg";
import ErgonomicsIcon from "../images/icons/ergonomics-icon.svg";

const Wrapper = styled.div`
  background: url(${Background});
  min-height: 578px; 
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  h2 {
    margin: 0!important;
  }
  .benefits {
    height: 100%;
    min-height: 578px; 
    background-color: rgba(18,17,12,0.4);
    padding: 24px 0;
    @media only screen and (min-width: 768px) and (max-width: 1399px) {
      padding: 40px 0;
    }
    @media only screen and (min-width: 1400px) {
      padding: 40px 0 80px 0;
    }
`;

const Benefits = ({ directus }) => {
  const icons = {
    ProductivityIcon: ProductivityIcon,
    SafetyIcon: SafetyIcon,
    ErgonomicsIcon: ErgonomicsIcon,
  };

  return (
    <Wrapper>
      <div id="benefits">
        <div className="benefits">
          <Container>
            <H4>{directus.translations[0].benefits_subline}</H4>

            <H2>
              {directus.translations[0].benefits_headline
                .split("\n")
                .map((item) => {
                  return (
                    <>
                      {item} <br />
                    </>
                  );
                })}
            </H2>

            <Row>
              {directus.benefits.map((benefit) => {
                  const icon = icons[benefit.benefits_id.icon];
                  const title = benefit.benefits_id.translations[0].title;
                  const text = benefit.benefits_id.translations[0].text;
                  const backgroundColor = benefit.benefits_id.backgroundcolor;
                  const textColor = benefit.benefits_id.textcolor;

                  return (
                    <Col xs={12} md={4} key={title}>
                      <Benefit
                        icon={icon}
                        title={title}
                        text={text}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                      />
                    </Col>
                  );
              })}
            </Row>
          </Container>
        </div>
      </div>
    </Wrapper>
  );
};

export default Benefits;
