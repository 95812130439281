import React from "react";
import { H2, H4 } from "./basis/headlines";

import styled from "styled-components";

import { Container } from "react-bootstrap";

import RecommendedSlider from "./recommendedSlider";

const Wrapper = styled.div`
  .recommendedProducts {
    background-color: #12110C;
    padding: 24px 0;
    @media only screen and (min-width: 768px) and (max-width: 1399px) {
      padding: 40px 0;
    }
    @media only screen and (min-width: 1400px) {
      padding: 80px 0;
    }
  }
`;

const RecommendedProducts = ({ directus }) => (
  <Wrapper>
    <div id="recommendedProducts">
      <div className="recommendedProducts">
        <Container>
          <H4>{directus.translations[0].recommended_products_subline}</H4>
          <H2>{directus.translations[0].recommended_products_headline}</H2>
          <RecommendedSlider products={directus.products} />
        </Container>
      </div>
    </div>
  </Wrapper>
);

export default RecommendedProducts;
