import React from "react";
import styled from "styled-components";
import Fonts1 from "../fonts/Font1.ttf";
import Fonts2 from "../fonts/Font2.ttf";


import Logo from "../assets/logo.svg";

const Wrapper = styled.div`
  /*Social media icons*/
  .footer {
    background-color: #12110C;
  }
  @font-face {
    font-family: "porto-icons";
    src: url(${Fonts1}) format("truetype");
  }
  [class^="porto-icon-"]:before,
  [class*=" porto-icon-"]:before {
    font-family: "porto-icons";
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .porto-icon-linkedin-squared:before {
    content: "\f835";
  }
  .porto-icon-facebook:before {
    content: "\f833";
  }
  .porto-icon-instagram:before {
    content: "\f83d";
  }

  .footer-middle .social-icons a {
    background-image: none;
    text-indent: 0;
    color: #fff;
    border-radius: 0;
    font-size: 30px;
    height: 37px;
    text-align: center;
    margin-left: 0;
    margin-right: 4px;
    float: left;
    line-height: 35px;
  }
  /*Button font Oswald*/
  @font-face {
    font-family: "oswald";
    src: url(${Fonts2}) format("truetype");
  }

  footer [class*="col-"] {
    float: left;
  }

  footer .row::after {
    content: "";
    clear: both;
    display: table;
  }

  /*Footer middle*/

  .footer-middle {
    padding: 62px 0 42px;
    font-size: 13px;
  }
  .footer-middle p {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .footer-middle p {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .footer-middle ul.links li,
  .footer-middle ul.features li {
    padding: 6px 0;
  }

  .footer-middle ul {
    list-style-type: none;
  }

  .footer-middle ul.links {
    margin-top: -5px;
  }

  .footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .footer-middle a {
    text-decoration: none;
  }

  .footer-middle .contact-info li:first-child {
    border-top-width: 0;
  }

  .footer-middle .contact-info li {
    padding: 9px 0;
  }
  .footer-middle .block .block-title {
    margin-bottom: 15px;
  }

  .footer-middle .block .block-title strong {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .footer-middle .block-bottom {
    border-top: 1px solid #3d3d38;
    text-align: left;
    padding: 27px 0;
    overflow: hidden;
  }
  .footer-middle .contact-info b {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 7px;
    display: inline-block;
  }
  .footer-middle .block.newsletter .form.subscribe {
    max-width: 400px;
  }

  .footer-middle .block.newsletter .control:before {
    line-height: 48px;
    margin-left: 20px;
  }

  .footer-middle .block.newsletter .control {
    position: relative;
  }

  .footer-middle .block.newsletter .control input {
    background-color: #fff;
    color: #686865;
    height: 48px;
    border: none;
    font-size: 14px;
    padding-left: 10px;
    width: 100%;
  }

  .footer-middle .block.newsletter .control div.mage-error {
    position: absolute;
    bottom: -35px;
  }

  .footer-middle .block.newsletter .action.subscribe {
    height: 48px;
    text-transform: uppercase;
    padding: 0 22px;
  }
  .footer-middle .block.newsletter .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%;
  }
  .footer-middle .block.newsletter {
    width: auto;
  }
  .footer-middle .block {
    text-align: left;
    line-height: 1;
    margin: 0;
    width: auto;
  }
  .block.newsletter .form.subscribe {
    display: table;
    width: 100%;
  }
  .block.newsletter .field {
    margin: 0;
  }

  /*footer coloring*/
  .footer-middle,
  .footer-middle p {
    color: #eceded;
  }

  .footer-middle a {
    color: #eceded;
  }

  .footer-middle a:hover {
    color: #ffdd00;
  }
  .footer-middle [class^="porto-icon-"].theme-color,
  .footer-middle [class*=" porto-icon-"].theme-color {
    color: #eceded !important;
  }
  .footer-bottom,
  .footer-bottom p {
    color: #eceded;
  }

  .footer-bottom a {
    color: #eceded;
  }

  .footer-bottom a:hover {
    color: #eceded;
  }

  .footer-bottom {
    padding: 18px 0;
  }

  .footer-bottom .logo {
    margin: 0;
    margin-right: 45px;
    height: 48px;
    width: auto;
    margin-bottom: 42px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  #ot-sdk-btn.ot-sdk-show-settings,
  #ot-sdk-btn.optanon-show-settings {
    color: inherit !important;
    border: none !important;
    padding: 0 !important;
    background-color: inherit;
  }

  /*all buttons*/
  button.action.primary {
    background-color: #ffdd00;
    padding: 8px 51px;
    position: relative;
    color: #12110C;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: block;
    width: fit-content;
    transition: all 0.3s ease-in;
    border: none;
  }
  .action.primary:hover,
  .action.primary:focus,
  .action.primary:active,
  .action.primary:hover {
    color: #12110C;
    background-color: #ffdd00;
    border: none;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  button.action.primary after {
    content: "";
    border: none;
  }

  /*footer bottom*/
  .footer-bottom {
    padding: 0px 0;
  }

  .footer-bottom .container {
    position: relative;
  }

  .footer-bottom .container:before {
    content: "";
    position: absolute;
    background-color: #3d3d38;
    left: 15px;
    right: 15px;
    top: -18px;
    height: 1px;
    width: calc(100% - 30px);
  }

  .footer-bottom .container {
    display: flex;
    font-size: 13px;
  }

  .footer-bottom address {
    line-height: 30px;
    margin: 0;
  }

  .footer-bottom .container {
    position: relative;
  }

  /*breakpoints*/

  @media (max-width: 991px) {
    .footer-middle .block .block-content {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 576px) {
    .footer-bottom .container {
      display: block;
    }
  }
  @media (max-width: 768px) {
     {
      .abstand {
        padding-left: 5px;
      }
    }
  }
`;

/*english footer*/

const Footer = ({ pageContext }) => {
  if (pageContext.language === "de-DE") {
    return (
      <Wrapper>
        <div className="footer">
          <div className="footer-middle block-bottom">
            <div className="container">
              <div className="row abstand">
                <div className="col-lg-3">
                  <div className="block">
                    <div className="block-title">
                      <strong>
                        <span>Kontaktinformation</span>
                      </strong>
                    </div>
                    <div className="block-content">
                      <ul className="contact-info" style={{ paddingTop: 7 }}>
                        <li>
                          <p>
                            <b style={{ color: "#fff" }}>
                              RODCRAFT Central Europe
                            </b>
                            <br />
                            Edmund-Seng-Str. 3-5
                            <br />
                            63477 DE-Maintal
                            <br />
                          </p>
                        </li>
                        <p>
                          <b style={{ color: "#fff" }}>Telefon:</b>
                          <br />
                          <a href="tel:+496181411130">+49 (0)6181 411-130</a>
                        </p>
                        <li>
                          <p>
                            <a
                              target="_blank"
                              href="https://www.rodcraft.com/de/support-desk/"
                            >
                              <button
                                className="action subscribe primary"
                                title="Kontaktanfrage"
                                type="submit"
                              >
                                <span>Kontaktanfrage</span>
                              </button>
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="block" style={{ marginBottom: 20 }}>
                        <div className="block-title">
                          <strong>RODCRAFT NEWSLETTER</strong>
                        </div>
                        <div className="block-content">
                          <p>
                            Abonnieren Sie unseren Newsletter mit aktuellsten
                            Informationen über Produkte, Aktionen und Neuheiten.
                            Melden Sie sich mit Ihrer E-Mail Adresse für unseren
                            Newsletter an.
                          </p>
                        </div>
                        <br />
                        <p>
                          <a
                            target="_blank"
                            href="https://www.rodcraft.com/de/newsletter-abonnement/"
                          >
                            <button
                              className="action subscribe primary"
                              title="Jetzt abonnieren"
                              type="submit"
                            >
                              Jetzt abonnieren
                            </button>
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 ">
                      <div className="row">
                        <div className="block" style={{ marginBottom: 20 }}>
                          <div className="block-title">
                            <strong>SOCIALMEDIA</strong>
                          </div>
                          <div className="block-content">
                            <div
                              className="social-icons"
                              style={{ float: "left" }}
                            >
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/company/rodcraft/"
                                title="Linkedin"
                              >
                                <i className="porto-icon-linkedin-squared" />
                              </a>
                              <a
                                target="_blank"
                                href="https://www.facebook.com/Rodcraft.de/"
                                title="Facebook"
                              >
                                <i className="porto-icon-facebook" />
                              </a>
                              <a
                                target="_blank"
                                href="https://instagram.com/rodcraft_central_europe?igshid=101l84ju0q1fc"
                                title="Instagram"
                              >
                                <i className="porto-icon-instagram" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="block" style={{ marginBottom: 20 }}>
                          <a
                            target="_blank"
                            href="https://www.rodcraft.com/de/zahlungsweisen/"
                          >
                            <div className="block-title">
                              <strong>SICHERE ZAHLUNGEN</strong>
                            </div>
                            <div className="block-content">
                              <div
                                className="social-icons"
                                style={{ float: "left" }}
                              >
                                <img
                                  src="https://www.rodcraft.com/media/wysiwyg/payments.png"
                                  alt=""
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                        <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                      </div>
                      <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                    </div>
                    <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                  </div>
                  <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                  <div className="row">
                    <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                    <div className="col-sm-12">
                      <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                      <div className="block-bottom">
                        <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                        <div className="row">
                          <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                          <div className="col-sm-5">
                            <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                            <div className="block">
                              <a href="https://www.rodcraft.com/de/zahlungsweisen/">
                                <div className="block-title">
                                  <strong>KONTO UND EINSTELLUNGEN</strong>
                                </div>
                              </a>
                              <div className="block-content">
                                <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                <div className="row">
                                  <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                  <div className="col-sm-6">
                                    <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                    <ul className="links">
                                      <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                      <li>
                                        <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                        <a href="https://www.rodcraft.com/de/customer/account/">
                                          Mein Account
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.rodcraft.com/de/zahlungsweisen/" />
                                        <a href="https://www.rodcraft.com/de/customer/account/login/">
                                          Login
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.rodcraft.com/de/faq/">
                                          Fragen und Antworten
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.rodcraft.com/de/lieferungen/">
                                          Lieferungen
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.rodcraft.com/de/zahlungsweisen/">
                                          Zahlungsweisen
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.rodcraft.com/de/sitemap/">
                                          Seitenverzeichnis
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-5">
                            <div className="block">
                              <div className="block-title">
                                <strong>BENUTZERBEDINGUNGEN</strong>
                              </div>
                              <div className="block-content">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <ul className="links">
                                      <li>
                                        <a href="https://www.rodcraft.com/de/terms-and-conditions/">
                                          AGBs
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          title="Nutzungsbedingungen der Website"
                                          href="https://www.rodcraft.com/de/nutzungsbedingungen-der-website/"
                                        >
                                          Nutzungsbedingungen der Website
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          title="Impressum"
                                          href="https://www.rodcraft.com/de/Impressum/"
                                        >
                                          Impressum
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          title="Datenschutzerklärung"
                                          href="https://www.rodcraft.com/de/datenschutzerklarung/"
                                        >
                                          Datenschutzerklärung
                                        </a>
                                      </li>
                                      <li>
                                        <button
                                          id="ot-sdk-btn"
                                          className="ot-sdk-show-settings"
                                        >
                                          Einstellungen bearbeiten
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container ">
              <strong className="logo">
                <img src={Logo} alt="Logo" />
              </strong>
              <address>© Rodcraft. 2021. Alle Rechte vorbehalten</address>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <div className="footer">
          <div className="footer-middle block-bottom">
            <div className="container">
              <div className="row abstand">
                <div className="col-lg-3">
                  <div className="block">
                    <div className="block-title">
                      <strong>
                        <span>Contact Information</span>
                      </strong>
                    </div>
                    <div className="block-content">
                      <ul className="contact-info" style={{ paddingTop: 7 }}>
                        <li>
                          <p>
                            <b style={{ color: "#fff" }}>SALES OFFICE</b>
                            <br />
                            38, rue Bobby Sands
                            <br />
                            ZAC de la Lorie
                            <br />
                            44800 Saint Herblain - France
                            <br />
                          </p>
                        </li>
                        <p>
                          <b style={{ color: "#fff" }}>Phone:</b>
                          <br />
                          <a href="tel:+33240802000">+33 (0)2 40 80 20 00</a>
                        </p>
                        <li>
                          <p>
                            <a
                              target="_blank"
                              href="https://www.rodcraft.com/gb/global-contact/"
                            >
                              <button
                                className="action subscribe primary"
                                title="Contact form"
                                type="submit"
                              >
                                <span>Contact us</span>
                              </button>
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="block" style={{ marginBottom: 20 }}>
                        <div className="block-title">
                          <strong>Stay connected with us:</strong>
                        </div>
                        <div className="block-content">
                          <p>
                            Subscribe to our newsletter: get all the latest
                            information on products, sales and news.
                            <br />
                            Subscribe to our newsletter today by entering your
                            email address
                          </p>
                        </div>
                        <br />
                        <div
                          className="block-content"
                          style={{
                            marginTop: 20,
                            marginBottom: 38,
                            width: "100%",
                          }}
                        >
                          <div className="block newsletter">
                            <div className="content">
                              <form
                                className="form subscribe"
                                noValidate="novalidate"
                                action="https://www.rodcraft.com/gb/newsletter/subscriber/new/"
                                method="post"
                                id="newsletter-validate-detail"
                              >
                                <div className="field newsletter">
                                  <div className="control">
                                    <input
                                      name="email"
                                      type="email"
                                      id="footer_newsletter"
                                      data-validate="{required:true, 'validate-email':true}"
                                      placeholder="Email Address"
                                    />
                                  </div>
                                </div>
                                <div className="actions">
                                  <button
                                    className="action subscribe primary"
                                    title="Subscribe"
                                    type="submit"
                                  >
                                    <span>Subscribe</span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 ">
                      <div className="row">
                        <div className="block" style={{ marginBottom: 20 }}>
                          <div className="block-title">
                            <strong>SOCIALMEDIA</strong>
                          </div>
                          <div className="block-content">
                            <div
                              className="social-icons"
                              style={{ float: "left" }}
                            >
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/company/rodcraft/"
                                title="Linkedin"
                              >
                                <i className="porto-icon-linkedin-squared" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                      </div>
                      <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                    </div>
                    <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                  </div>
                  <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                  <div className="row">
                    <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                    <div className="col-sm-12">
                      <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                      <div className="block-bottom">
                        <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                        <div className="row">
                          <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                          <div className="col-sm-5">
                            <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                            <div className="block">
                              <a href="https://www.rodcraft.com/de/zahlungsweisen/">
                                <div className="block-title">
                                  <strong>My account</strong>
                                </div>
                              </a>
                              <div className="block-content">
                                <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                <div className="row">
                                  <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                  <div className="col-sm-6">
                                    <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                    <ul className="links">
                                      <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                      <li>
                                        <a href="https://www.rodcraft.com/de/zahlungsweisen/"></a>
                                        <a href="https://www.rodcraft.com/gb/about_rodcraft/">
                                          About Rodcraft
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.rodcraft.com/de/zahlungsweisen/" />
                                        <a href="https://www.rodcraft.com/gb/global-contact/">
                                          Customer Centers
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.rodcraft.com/gb/uk-legal/">
                                          Legal Notice, Privacy Policys
                                        </a>
                                      </li>
                                      <li>
                                        <button
                                          id="ot-sdk-btn"
                                          className="ot-sdk-show-settings"
                                        >
                                          Edit settings
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-sm-6">
                                    <ul className="links">
                                      <li>
                                        <a href="https://www.rodcraft.com/gb/catalogsearch/advanced/">
                                          Advanced Search
                                        </a>
                                      </li>
                                      <li>
                                        <a href="https://www.rodcraft.com/gb/sitemap/">
                                          Site map
                                        </a>
                                      </li>
                                      {/*<li><a href="https://www.rodcraft.com/gb/customer/account/login/">Login</a></li>*/}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container ">
              <strong className="logo">
                <img src={Logo} alt="Logo" />
              </strong>
              <address>© Rodcraft. 2021. Alle Rechte vorbehalten</address>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
};

export default Footer;
