import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Imagespacer from "../components/imagespacer";
import Hero from "../components/hero";
import Button from "../components/button";
import RecommendedProducts from "../components/recommendedProducts";
import Facts from "../components/facts";

import GlobalStyles from "../components/globalStyles";
import ScrollToTop from "../components/scrolltotop";
import Benefits from "../components/benefits";
import ProductIntroducing from "../components/productIntroducing";
import ProductAbout from "../components/productAbout";

const Rh136Template = ({ data: { directus }, pageContext }) => (
  <>
    <Helmet title={directus.layouts[0].translations[0].title} defer={false} />
    <GlobalStyles />
    <Navigation pageContext={pageContext} directus={directus.layouts[0]} />
    <Button directus={directus.layouts[0]} />
    <Hero directus={directus.layouts[0]} />
    <ProductIntroducing directus={directus.layouts[0]} />
    <Benefits directus={directus.layouts[0]} />
    <ProductAbout directus={directus.layouts[0]} />
    <Facts directus={directus.layouts[0]} />
    <Imagespacer />
    <RecommendedProducts directus={directus.layouts[0]} />
    <ScrollToTop />
    <Footer pageContext={pageContext} />
  </>
);

export const query = graphql`
  query DirectusQuery($layout: Float, $language: String) {
    directus {
      layouts(filter: { id: { _eq: $layout } }) {
        name
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          title

          hero_subline
          hero_headline

          go_to_store_link_label
          go_to_store_link_link

          benefits_subline
          benefits_headline

          recommended_products_subline
          recommended_products_headline

          product_about_subline
          product_about_headline
          product_about_text

          product_introducing_subline
          product_introducing_headline
          product_introducing_text

          facts_subline
          facts_headline
          facts_productivity
          facts_safety
          facts_ergonomics
        }
        benefits {
          benefits_id {
            id
            textcolor
            backgroundcolor
            icon
            translations(
              filter: { languages_id: { code: { _eq: $language } } }
            ) {
              text
              title
            }
          }
        }
        products {
          products_id {
            name
            translations(
              filter: { languages_code: { code: { _eq: $language } } }
            ) {
              title
              link
            }
          }
        }
        facts {
          facts_id {
            positionleft
            positiontop
            tab
            number
            image
            translations(
              filter: { languages_code: { code: { _eq: $language } } }
            ) {
              title
              text
            }
          }
        }
      }
    }
  }
`;

export default Rh136Template;
