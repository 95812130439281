import React from "react";
import Slider from "react-slick";

import RC2277 from "../images/RC2277.png";
import UBZ3 from "../images/UBZ3.png";
import RS413WP from "../images/RS413WP.png";
import RDP10 from "../images/RDP10.png";
import RF02G from "../images/RF02G.png";
import DMS200 from "../images/DMS200.png";
import RC7088 from "../images/RC7088.png";
import UBZ2 from "../images/ubz2.png";
import RC2268 from "../images/rc2268.png";

import RecommendedProduct from "./recommendedProduct";

class RecommendedSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 1000,
      slidesToShow: 4,
      rows: 2,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1399,
          settings: {
            centerMode: true,
            slidesToShow: 2,
            rows: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            rows: 1,
          },
        },
      ],
    };

    const images = {
      RC2277: RC2277,
      "UBZ-3": UBZ3,
      RS413WP: RS413WP,
      RDP10: RDP10,
      RF02G: RF02G,
      DMS200: DMS200,
      RC7088: RC7088,
      'UBZ-2': UBZ2,
      RC2268: RC2268,
    };

    return (
      <Slider {...settings}>
        {this.props.products.map((product) => {
          return (
            <div key={product.products_id.name}>
              <RecommendedProduct
                image={images[product.products_id.name]}
                articleNumber={product.products_id.name}
                title={product.products_id.translations[0].title}
                link={product.products_id.translations[0].link}
              />
            </div>
          );
        })}
      </Slider>
    );
  }
}

export default RecommendedSlider;
