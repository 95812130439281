import React from "react";

import styled from 'styled-components'

import { Button, Popover, OverlayTrigger } from 'react-bootstrap'


const Wrapper = styled.div`
  .btn-secondary {
    position: absolute;
    left: ${props => props.left};
    top: ${props => props.top};
    color: white;
    font-weight: bold;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    font-size: 22px;
    background-color: rgba(18,17,12,0.95);
    border: none;
    transition: all 0.3s ease-in-out;
    &[aria-describedby="popover-positioned-top"], &:focus {
      background-color: rgba(255,255,255,0.9);
      color: black;
    }
    &:hover {   
      background-color: rgba(255,255,255,0.9);
      color: black;
    }
  }
`

const PopoverOverlay = ({title, text, number, positionLeft, positionTop, image, ...rest}) => {
  return (
    <Wrapper left={positionLeft} top={positionTop} {...rest}>
            <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose="true"
                overlay={
                <Popover id={`popover-positioned-top`}>
                <Popover.Body>
                  <div>
                    <strong>{title}</strong><br /> {text}
                  </div>
                  {image && <img src={image} alt={title} />}
                </Popover.Body>
                </Popover>
            }
            >
          
          <Button variant="secondary">{number}</Button>
            </OverlayTrigger>
    </Wrapper>
  );
}

export default PopoverOverlay