import React from "react";

import styled from 'styled-components'


const Wrapper = styled.div`
  height: 100%;
  .benefit {
      height: calc(100% - 48px);
      margin-top: 48px;
      display: block;
      position: relative;
      background-color: ${props => props.background};
      border-radius: 8px;
      padding: 40px 16px 16px 16px;
      color: ${props => props.textColor};
      .benefitHeadline {
        font-weight: bold; 
        font-size: 18px;
        line-height: 26px;
        text-transform: uppercase;
        @media only screen and (min-width: 768px) and (max-width: 1399px) {
            font-size: 20px;
            line-height: 28px;
        }
        @media only screen and (min-width: 1400px) {
            font-size: 22px;
            line-height: 32px;
        }
      }
      .benefitText {
        font-size: 16px;
        line-height: 24px;
        @media only screen and (min-width: 768px) and (max-width: 1399px) {
            font-size: 20px;
            line-height: 30px;
        }
        @media only screen and (min-width: 1400px) {
            font-size: 22px;
            line-height: 32px;
        }
      }
      .benefitIcon {
        height: 56px;
        width: 56px;
        position: absolute;
        left: 0;
        top: -28px;
        border-radius: 50%;
        box-shadow: 0em 0em 0px 2px white;
        display: inline-table;
        background-color: ${props => props.background};
        svg, image, img{
            height: 56px;
            width: 56px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1399px) {
            height: 64px;
            width: 64px;
            top: -32px;
            svg, image, img {
                height: 64px;
                width: 64px;
            }
        }
        @media only screen and (min-width: 1400px) {
            height: 96px;
            width: 96px;
            top: -48px;
            svg, image, img {
                height: 96px;
                width: 96px;
            }
        }
      }
      @media only screen and (min-width: 768px) and (max-width: 1399px) {
        height: calc(100% - 72px);
        margin-top: 72px;
        padding: 48px 16px 24px 16px;
      }

      @media only screen and (min-width: 1400px) {
        height: calc(100% - 80px);
        margin-top: 80px;
        padding: 64px 16px 24px 16px;
      }
  }
`

const Benefit = ({ icon, title, text, backgroundColor, textColor, ...rest }) => {
  return (
    <Wrapper background={backgroundColor} textColor={textColor} {...rest}>

              <div className="benefit">
                <div className="benefitIcon"><img src={icon} alt="icon" /></div>
                <div className="benefitHeadline">{title}</div>
                <div className="benefitText">{text}</div>
              </div>
    </Wrapper>
  );
}

export default Benefit