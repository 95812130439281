import styled from 'styled-components'

export const H2 = styled.h2`
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: rgb(255, 255, 255);
  line-height: 30px;
  text-transform: uppercase;
  @media only screen and (min-width: 768px) and (max-width: 1399px) {
    font-size: 32px;
    line-height: 36px;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 48px;
    line-height: 60px;
  }
`

export const H4 = styled.h4`
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: rgb(255, 221, 0);
  line-height: 18px;
  text-transform: uppercase;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`
