import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Fonts1 from "../fonts/Font1.ttf";

const Wrapper = styled.div`
/*to top button*/

@font-face {
  font-family: "porto-icons";
  src: url(${Fonts1}) format("truetype");
}
[class^="porto-icon-"]:before,
[class*=" porto-icon-"]:before {
  font-family: "porto-icons";
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.porto-icon-up-open:before {
  content: '\f800';
}
#totop {
    height: 40px;
    position: fixed;
    right: 15px;
    width: 40px;
    z-index: 9999;
    bottom: 0;
    background-color: #404040;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 1;
    padding: 11px 0;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`



export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 2000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Wrapper>
    <div>
      {isVisible && (
        <div onClick={scrollToTop} id="totop">
          <i className="porto-icon-up-open"  />
        </div>
      )}
    </div>
    </Wrapper>
  );
}