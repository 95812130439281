import React from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import Arrow from "../images/icons/arrow.svg";

const Wrapper = styled.div`
.btn-primary {
    z-index:100;
    width:auto;
    background-color: #ffdd00;
    width: auto;
    padding: 8px 51px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: black;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: block;
    transition: all 0.3s ease-in;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary:hover {
    color: black;
    background-color: #ffdd00;
    border: none;
    text-decoration-thickness: 2px;
  }
  #button{
    padding-left: 15px;

  }
  @media (max-width: 768px) {
      .btn-primary{
        width: calc(100% - 16px);
        top: unset;
        bottom: 0;
        left: 8px;
        right: 8px;
        height:40px;
      }
  }
  @media (min-width: 768px) and (max-width: 991.98px) { 
    .btn-primary {
      width: calc(100% - 464px);
      top: unset;
      bottom: 0;
      left: 232px;
      right: 232px;
      height:40px;
    }
   }
}
`;

const Button = ({ directus }) => (
  <Wrapper>
    <a
      target="_blank"
      href={directus.translations[0].go_to_store_link_link}
      className=" btn-primary"
    >
      {directus.translations[0].go_to_store_link_label}
      {/* <img src={Arrow} alt="" id="button" /> */}
    </a>
  </Wrapper>
);

export default Button;
