import React from "react";
import styled from "styled-components";
import HeroImg from "../images/hero.png";
import "bootstrap/dist/css/bootstrap.min.css";

const Wrapper = styled.div`
  .teaser-bg {
    background: url(${HeroImg});
    background-size: cover;
    background-position: center;
    height: 1080px;
    width: 100%;
  }

  .teaser-content {
    background-color: rgba(18,17,12,0.4);
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .teaser-container h1 {
    color: white;
    font-weight: 700;
    font-size: 80px;
    line-height: 88px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 24px;
    max-width: 1319px;
  }

  .teaser-container p {
    color: #ffdd00;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  /*smaller lg*/
  @media (max-width: 992px) {
    .btn-primary {
      padding: 8px 24px;
      padding-right: 56px;
    }
  }
  @media (max-width: 992px) {
    .teaser-container h1 {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 16px;
    }

    .teaser-bg {
      min-height: unset;
      height: 80vh;
    }
  }

  /*smaller sm*/
  @media (max-width: 768px) {
    .teaser-container h1 {
      font-size: 34px;
      line-height: 36px;
      margin-bottom: 16px;
    }

    .teaser-bg {
      height: 80vh;
      position: relative;
    }
  }
`;
const Hero = ({ directus }) => (
  <Wrapper>
    <div className="teaser-container teaser-bg">
      <div className="teaser-content">
        <p>{directus.translations[0].hero_subline}</p>
        <h1 className="herotype">{directus.translations[0].hero_headline}</h1>
      </div>
    </div>
  </Wrapper>
);

export default Hero;
