
import React from 'react'
import styled from 'styled-components'
import ImagespacerImg from "../images/parallax.jpeg";

const Wrapper = styled.div`
/* imagespacer */
.parallax {
  background-image: url(${ImagespacerImg});
  overflow: hidden;
  height: 480px;
  width: 100%;
  margin: 0 auto;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
`

const Imagespacer = () => (
    <Wrapper>
       <div className="parallax">
        </div>
    </Wrapper>
)

export default Imagespacer
