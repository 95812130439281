import { createGlobalStyle } from 'styled-components'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const GlobalStyles = createGlobalStyle`


  html {
    box-sizing: border-box;
    font-size: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  *:focus {
    outline: none;
  }

  html, body {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
  }
  @media only screen and (max-width: 1399px) {
    .slick-list {
      padding: 0!important;
    }
  }

  #introducing, #benefits, #about, #facts, #recommendedProducts {
    :target:before {
      content: "";
      display: block;
      height: 115px;
      margin: -115px 0 0;
    }
  }


  .popover-arrow {
    display: none!important;
  }
  .popover {
    border: none!important;
    border-radius: 0!important;
    background-color: rgba(255, 255, 255, 0.9)!important;
    padding: 8px;
    font-size: 18px!important;
    color: black;
    display: flex;

    @media only screen and (min-width: 768px) and (max-width: 1399px) {
      padding: 16px;
    }
    @media only screen and (min-width: 1400px) {
      padding: 24px;
    }
    max-width: 350px!important;
    .popover-body {
      display: flex;
      color: black;
    }
    strong {
      text-transform: uppercase;
    }
    img {
      width: 160px;
      margin-top: -75px;
      height: auto;
      object-fit: contain;
    }
  }

`

export default GlobalStyles
