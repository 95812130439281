import React from "react";
import { H2, H4 } from "./basis/headlines";

import styled from "styled-components";

import { Container, Row, Col, Tab, Nav } from "react-bootstrap";

import PopoverOverlay from "./popoverOverlay";

import Background from "../images/RH136_3D-left.png";
import overlayFastIcon from "../images/overlay-fast-icon.png";

const Wrapper = styled.div`
  .facts-bg {
    background: radial-gradient(
      circle,
      rgba(60, 60, 60, 1) 0%,
      rgba(18, 17, 12, 1) 50%
    );
    min-height: 960px;
    height: 100%;
    text-align: center;
    position: relative;
    .relative {
      position: relative;
    }
    .facts {
      height: 100%;
      min-height: 960px;
      width: 100%;
      padding: 24px 0;

      img {
        max-width: 100%;
      }

      @media only screen and (min-width: 768px) and (max-width: 1399px) {
        padding: 40px 0;
      }
      @media only screen and (min-width: 1400px) {
        padding: 80px 0;
      }
      .nav-pills {
        flex-direction: row !important;
        justify-content: center;
        align-items: center;
        width: 100%;
        @media only screen and (min-width: 768px) {
          width: auto;
          position: absolute;
          flex-direction: column !important;
          top: 50%;
          transform: translateY(-50%);
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
      .nav-item {
        @media only screen and (min-width: 1399px) {
          min-width: 400px;
        }
        a {
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          color: white;
          font-weight: bold;
          font-size: 16px;
          line-height: 30px;
          text-align: left;
          margin: 16px 10px;
          border-radius: 0;
          padding: 1px 8px;
          &.active {
            background-color: transparent;
            color: #ffdd00;
          }
          &:hover {
            color: #ffdd00;
          }
          @media only screen and (min-width: 768px) and (max-width: 1399px) {
            padding: 8px 72px 8px 16px;
            font-size: 22px;
            line-height: 32px;
          }
          @media only screen and (min-width: 1400px) {
            padding: 20px 88px 20px 32px;
            font-size: 32px;
            line-height: 40px;
          }
        }
      }
    }
  }
`;

const Facts = ({ directus }) => {
  const images = {
    overlayFastIcon: overlayFastIcon,
  };

  return (
    <Wrapper>
      <div id="facts">
        <div className="facts-bg">
          <div className="facts">
            <Container>
              <H4>{directus.translations[0].facts_subline}</H4>

              <H2>{directus.translations[0].facts_headline}</H2>

              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="productivity"
              >
                <Row>
                  <Col md={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="productivity">
                          {directus.translations[0].facts_productivity}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="safety">
                          {directus.translations[0].facts_safety}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="ergonomics">
                          {directus.translations[0].facts_ergonomics}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={9} xl={6} className="relative">
                    <img src={Background} alt="background" />
                    <Tab.Content>
                      <Tab.Pane eventKey="productivity">
                        {directus.facts.map((fact) => {
                          if (fact.facts_id.tab === "productivity") {
                            return (
                              <PopoverOverlay
                                positionLeft={fact.facts_id.positionleft}
                                positionTop={fact.facts_id.positiontop}
                                title={fact.facts_id.translations[0].title}
                                text={fact.facts_id.translations[0].text}
                                image={images[fact.facts_id.image]}
                                number={fact.facts_id.number}
                              />
                            );
                          }
                        })}
                      </Tab.Pane>
                      <Tab.Pane eventKey="safety">
                        {directus.facts.map((fact) => {
                          if (fact.facts_id.tab === "safety") {
                            return (
                              <PopoverOverlay
                                positionLeft={fact.facts_id.positionleft}
                                positionTop={fact.facts_id.positiontop}
                                title={fact.facts_id.translations[0].title}
                                text={fact.facts_id.translations[0].text}
                                image={fact.facts_id.image}
                                number={fact.facts_id.number}
                              />
                            );
                          }
                        })}
                      </Tab.Pane>
                      <Tab.Pane eventKey="ergonomics">
                        {directus.facts.map((fact) => {
                          if (fact.facts_id.tab === "ergonomics") {
                            return (
                              <PopoverOverlay
                                positionLeft={fact.facts_id.positionleft}
                                positionTop={fact.facts_id.positiontop}
                                title={fact.facts_id.translations[0].title}
                                text={fact.facts_id.translations[0].text}
                                image={fact.facts_id.image}
                                number={fact.facts_id.number}
                              />
                            );
                          }
                        })}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Container>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Facts;
