import React from "react";

import styled from "styled-components";

import { ReactComponent as ArrowRight } from "../assets/arrow-right-white.svg";

const Wrapper = styled.div`
  .recommendedProduct {
        margin: 24px 0;
        .linkToProduct {
            text-decoration: none;
            transition: all 0.3s ease-in-out;
            &:hover {
                .recommendedProductTitle {
                    color: rgb(255, 221, 0);
                }
                .recommendedProductSubtitle {
                    color: rgb(255, 221, 0);
                }
                .recommendedProductImage {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .recommendedProductTitle {
          color: white;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 18px;
          line-heigh: 26px;
          height: 66px;
          @media only screen and (min-width: 768px) and (max-width: 1399px) {
              font-size: 20px;
              line-heigh: 28px;
          }
          @media only screen and (min-width: 1400px) {
              font-size: 22px;
              line-heigh: 32px;
          }
      }
      .recommendedProductSubtitle {
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 18px;
        line-heigh: 26px;
        @media only screen and (min-width: 768px) and (max-width: 1399px) {
            font-size: 20px;
            line-heigh: 28px;
        }
        @media only screen and (min-width: 1400px) {
            font-size: 22px;
            line-heigh: 32px;
        }
    }
        .recommendedProductImageBG {
            height: 276px;
            background: radial-gradient(circle, rgba(60,60,60,1) 0%, rgba(18,17,12,1) 50%);
            position: absolute;
            left: 0;
            right: 0; 
            top: 0;
            bottom: 0;
        }
        .recommendedProductImage {
            height: 276px;
            width: auto;
            object-fit: cover;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: cente
            transition: all 0.3s ease-in-out;r;
            z-index: 5;
            overflow: hidden;
            img {
                z-index: 5;
                transition: all 0.3s ease-in-out;
                padding: 32px;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
            
            @media only screen and (min-width: 768px) and (max-width: 1399px) {
            
                
            }
            @media only screen and (min-width: 1400px) {
            
                
            }
        }
  }
`;

const RecommendedProduct = ({ image, title, link, articleNumber, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <div className="recommendedProduct">
        <a href={link} className="linkToProduct" target="_blank">
          <div className="recommendedProductImage">
            <div className="recommendedProductImageBG"></div>
            <img src={image} alt={articleNumber} />
          </div>
          <div className="recommendedProductSubtitle">{articleNumber}</div>
          <div className="recommendedProductTitle">{title}</div>
          <ArrowRight />
        </a>
      </div>
    </Wrapper>
  );
};

export default RecommendedProduct;
